<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            Strate - школа дизайна, известная во Франции и во всем мире. Она имеет 3 кампуса: Севр (пригород Парижа), Лион и Бангалор (Индия).
            <br>
            Школа дизайна Strate выдает диплом дизайнера, трехлетний диплом специалиста по 3D-моделированию, диплом магистра в области инноваций и дизайна и степень MBA в области дизайна.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=zLwb-_F0u3I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            Fashion & design
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="di">Designer Industriel</option>
                                <option value="bm">Bachelor Modeleur 3D</option>
                                <option value="mid">Mastère Innovation & Design</option>
                                <option value="mtd">Master Transportation Design</option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'di'" id="di">
                        <template #title>
                            Designer Industriel
                        </template>
                        <template #duree>
                            5 лет
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            9.490-10.340 евро/год
                        </template>
                        <template #campus>
                            Париж, Лион
                        </template>
                        <template #requirements>
                            французский уровня B2, досье.
                        </template>
                        <template #start-time>
                            октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'bm'" id="bm">
                        <template #title>
                            Bachelor Modeleur 3D
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            9.490 - 9.990 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #requirements>
                            французский уровня B2, досье.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'mid'" id="mid">
                        <template #title>
                            Mastère Innovation & Design
                        </template>
                        <template #duree>
                            1 год
                        </template>
                        <template #language>
                            французский
                        </template>
                        <template #cost>
                            14.050 евро
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            октябрь
                        </template>
                        <template #requirements>
                            французский уровня B2, досье.
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'mtd'" id="mtd">
                        <template #title>
                             Master Transportation Design
                        </template>
                        <template #duree>
                            2 года
                        </template>
                        <template #language>
                            английский
                        </template>
                        <template #cost>
                            11.500 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #start-time>
                            сентябрь
                        </template>
                        <template #requirements>
                            французский уровня B2, досье.
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    Первоначально расположенный в Исси-ле-Мулино, первый кампус Strate Ecole de design, наконец, был основан в Севре, недалеко от Парижа, в окружении зелени, на территории Grand Paris Seine Ouest.
                </template>
            </Campus>

            <Campus>
                <template #localisation>
                    Лион
                </template>
                <template #description>
                    Кампус школы занимает площадь 2200 кв. Новый район Confluence, 
                    расположенный на южной оконечности Лионского полуострова, в месте слияния рек 
                    Рона и Сона, является одним из крупнейших городских проектов в центре города в Европе, 
                    усиливая привлекательность Лиона на международной арене.
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>