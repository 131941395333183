<template>
    <Introduction :url="'/brochure/strate'" :class="'introduction-strate'">  
        <template #title>
            Strate
        </template>
        <template #speciality>
            Fashion & Design
        </template>
        <template #min-duration>
            От 1 год
        </template>
        <template #cost>
            От 9.490€/год
        </template>
        <template #campus>
            Севр (пригород Парижа), Лион и Бангалор (Индия).
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>